import Vue from 'vue'
import Router from 'vue-router'
// import Home from '@/pages/Home'
// import Activities from "@/pages/Activities";
// import About from "@/pages/About";
// import Portfolio from "@/pages/Portfolio";
// import News from "@/pages/News";
import SinglePage from "@/pages/SinglePage";
import goTo from 'vuetify/es5/services/goto'
import "typeface-cookie";

Vue.use(Router)

export default new Router({
    mode: 'hash',
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }
        return goTo(scrollTo)
    },
    routes: [
        {
            path: '/',
            name: 'root',
            component: SinglePage
        }
        // ,
        // {
        //     path: '/Home',
        //     name: 'Home',
        //     component: Home
        // },
        // {
        //     path: '/activities',
        //     name: 'Activities',
        //     component: Activities
        // },
        // {
        //     path: '/about',
        //     name: 'About',
        //     component: About
        // },
        // {
        //     path: '/portfolio',
        //     name: 'Portfolio',
        //     component: Portfolio
        // },
        // {
        //     path: '/news',
        //     name: 'News',
        //     component: News
        // }
    ]
})
