import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(fas) // Include needed icons

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    theme: {
    themes: {
      light: {
        primary: "#ce128a",
        secondary: "#67308d",
        accent: "#b93342",
        error: "#fefee0",
        info: "#b9e3cc",
        success: "#4CAF50",
        warning: "#FFC107"
      },
      dark: {
        primary: "#ce128a",
        secondary: "#fefefe",
        accent: "#b93342",
        error: "#fefee0",
        info: "#b9e3cc",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
});
