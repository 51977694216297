<template>
    <div class="container" id="About">
        <div class="row">
            <div class="col-xs-12">
                <h2>About us</h2>
                <p>We are always passionate on new idea generation and development</p>
            </div>
        </div>
        <div class="row">
            <div v-for="(item, index) in serviceitems" :key="index"
                 class="col-xs-12 col-sm-4 col-md-4 text-left item_left">
                <v-card>
                    <v-img :lazy-src="item.image" :src="item.image"/>
                    <v-card-title>{{ item.title }}</v-card-title>
                    <v-card-text>{{ item.description }}</v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CAbout",
        data: () => ({
            serviceitems: [
                {
                    image: 'https://lh3.googleusercontent.com/jzfvyzv0SQ-lNFEE1S68hJRjPPyYuu5MeUQ1ldddBNz78_4HtLVIqSkSu1CJIstm8S_X-7JrpjzvVygjjYJR6KuOwti7G4TE5AoCt3wKoB51pdftpfS634ej3vuxVKFgA0w-OO6TmQ=w2400',
                    title: 'Raise Awareness',
                    description: 'Codettes aims to bring awareness to girls and young women active with Innovation and ICT in the ict sector'
                },
                {
                    image: 'https://lh3.googleusercontent.com/uGln_HffSS9LLGujpPDbQWzwz_ex_5sG9QTChJrKXs-DwnGEridhGseJflUh-JpkB9q8MnNYgnUB-r7qonTuvV5Q5iSbYKS1eV04Ajf1PXL_PfwukqYbzZkP86i9TbAwpCr6ecQfhw=w2400',
                    title: 'Educate &amp; Train',
                    description: 'Codettes aims to make girls and young women aware of the vast possibilities ICTs offer,and inviting them to envision their future as ICT creators through yearly Networking Events, Hackathons, Bootcamps and Trainings.'
                },
                {
                    image: 'https://lh3.googleusercontent.com/jEfrQOg80vHxHvoZTvkA2rDiklmfKcwksHUCEj2DUHHqSDS_jYhXDYz-Lq9sBBfyzxkC5ni2SU99uTWJoVh5Zpp95IWwbkolgZ1ffOjtvcHRPLwiPtECWeCyFNfY3i_WfOXzlDoNzA=w2400',
                    title: 'Develop Sector',
                    description: 'Codettes goal is to stimulate and develop and expand the Ict sector locally and regional through the codettes initiative.'
                }

            ]
        })
    }
</script>

<style scoped>

</style>