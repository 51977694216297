import { render, staticRenderFns } from "./YoutubeVid.vue?vue&type=template&id=142f5f74&scoped=true&"
import script from "./YoutubeVid.vue?vue&type=script&lang=js&"
export * from "./YoutubeVid.vue?vue&type=script&lang=js&"
import style0 from "./YoutubeVid.vue?vue&type=style&index=0&id=142f5f74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "142f5f74",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VCard,VCardText,VCardTitle,VLazy})
