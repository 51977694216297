<template>
    <div class="container" id="Activities">
        <div class="row">
            <div v-for="(item,index) in items" :key="index"
                 class="col-xs-12 col-sm-4 col-md-4 col-lg-4 text-center">
                <v-card class="mx-auto my-12" @click="$vuetify.goTo('#' + item.section, {})">
                    <v-img class="py-5">
                        <v-btn fab x-large color="primary">
                            <v-icon x-large>{{ item.icon }}</v-icon>
                        </v-btn>
                    </v-img>
                    <v-card-title>{{ item.title }}</v-card-title>
                    <v-card-text>{{ item.text }}</v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CActivities",
        data: () => ({
            items: [
                {
                    icon: 'fa fa-home',
                    title: 'Bootcamps',
                    text: 'Bootcamps are sponsored weekend programs that teach students idea building business planning computer programming and idea pitching in a short period of time.Participants are then trained to take part in events such as topic based Hackathons &amp; HackOmation competitions yearly organized by Codettes Organization.',
                    section: 'Bootcamps',
                },
                {
                    icon: 'fa fa-calendar',
                    title: 'Events',
                    text: 'Events are sponsored Networking Meeting Hackathons HackOmations & Bootcamps. During networking meetings girls and women active with innovation and ict are given the chance to share their ideas and experience and network with each other. Hackathons &amp; HackOmations are Events where participants are given topics based on real world problems and have to use their skillsets together in teams to build the best fit ICT solution. ',
                    section: 'Events',
                },
                {
                    icon: 'fa fa-laptop',
                    title: 'Tutorials/Moodle',
                    text: 'Codettes aim to educate and stimulate its community with their online platform with which they will be sharing online tutorials trainings experiences and discussion by members based on relative ICT topics and new trends.',
                    section: 'Tutorials',
                }
            ]
        })
    }
</script>

<style scoped>
    .v-card:hover {
        background-color: gray;
        margin-top: -60px;
    }

</style>
