<template>
    <div class="container" id="News">
        <h1>News</h1>
        <div class="row" id="Events">
            <div class="col-xs-12">
                <h2>Codettes Bootcamp and Inno Starter Students </h2>
                <p>We are always passionate on new idea generation and development</p>
            </div>
        </div>

        <div class="row">
            <div v-for=" (item, index) in newsitems2" :key="index" class="col-xs-12 col-sm-6 col-md-6">
                <youtube-vid :title="item.title" :description="item.description" :video="item.video"/>
            </div>
        </div>
        <div class="row">
            <div v-for=" (item, index) in newsitems" :key="index" class="col-xs-12 col-sm-6 col-md-6">
                <youtube-vid :title="item.title" :description="item.description" :video="item.video"/>
            </div>
        </div>
		<div class="row" id="Tutorials">
            <div class="col-xs-12">
                <h2>Codettes Tutorials</h2>
                <p>We are always passionate on new idea generation and development</p>
            </div>
        </div>
        <div class="row">
            <div v-for="(item, index) in tutorialitems" :key="index" class="col-xs-12 col-sm-6">
                <youtube-vid :title="item.title" :description="item.description" :video="item.video"/>
            </div>
        </div>
    </div>
</template>

<script>
    import YoutubeVid from "../components/YoutubeVid";

    export default {
        name: "CNews",
        components: {YoutubeVid},
        data: () => ({
            tutorialitems: [
                {
                    video: 'https://www.youtube.com/embed/videoseries?list=PLR6gltHhgLszp0RMmshGjU0apc2b2eZ6s',
                    title: "Codettes' Tutorial Playlist",
                    description: 'Linkit Smart 7688 Duo with Arduino Ide Pt.1 by Julie Sundar in Paramaribo Suriname'
                },
                {
                    video: 'https://www.youtube.com/embed/3ZfPdlYeeS8',
                    title: "Codettes' Tutorial Linkit Smart 7688 Duo with Arduino Ide Pt.1",
                    description: 'Linkit Smart 7688 Duo with Arduino Ide Pt.1 by Julie Sundar in Paramaribo Suriname'
                },
                {
                    video: 'https://www.youtube.com/embed/Ccjp5dmYcp8',
                    title: "Codettes' Interview Julie Sundar IOT Develpment",
                    description: "Codettes' Interview Julie Sundar IOT Develpment at ITU/CTU Conference Trinidad"
                },
                {
                    video: 'https://www.youtube.com/embed/NOLXcXJGBzo',
                    title: 'Raspberry Pi and Arduino Serial Communication',
                    description: 'Kosmos/It-Core HackOmation 2018 Installation Pt.2 Raspberry Pi and Arduino Serial Communication'
                }
            ],
            newsitems: [
                {
                    video: 'https://www.youtube.com/embed/videoseries?list=PLR6gltHhgLsyCZoffWbHzgDtJBzhL8dDA',
                    title: 'Kosmos Energy / Codettes Innostarter 2020 Presentation Playlist'
                },
                {
                    video: 'https://www.youtube.com/embed/yi7GA2vRpAs',
                    title: 'Kosmos Energy / Codettes Innostarter 2020 Presentation Shanice Ramdien'
                },
                {
                    video: 'https://www.youtube.com/embed/EEamVrl9fEE',
                    title: 'Kosmos Energy / Codettes Innostarter 2020 Adjako Manischa HandzOff'
                },
                {
                    video: 'https://www.youtube.com/embed/sUHy0N9cfXI',
                    title: 'Kosmos Energy / Codettes Innostarter 2020 Adjako Manischa HandzOff'
                }
            ],
              newsitems2: [
                {
                    video: 'https://www.youtube.com/embed/LbBk0L5b1j0',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Shanika Doekhi'
                },
                {
                    video: 'https://www.youtube.com/embed/Hi6J01XZcUE',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Amaris Tirtotaroeno '
                },
                {
                    video: 'https://www.youtube.com/embed/PeD2eNYZWeY',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Varun Brahmadin'
                },
                {
                    video: 'https://www.youtube.com/embed/NKt1AqUGjKs',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Zahira Abdoel'
                },
                {
                    video: 'https://www.youtube.com/embed/vKwXdoInkKY',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Keshav Haripersad & Asarel Jones'
                },
				{
                    video: 'https://www.youtube.com/embed/U9p1fb6pPEI',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Zahira Abdoel'
                },
				{
                    video: 'https://www.youtube.com/embed/pDa91dCRi_I',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Sueta Goeptar'
                },
				{
                    video: 'https://www.youtube.com/embed/wq93fKuWJDw',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Axl'
                },
				{
                    video: 'https://www.youtube.com/embed/1sfhudUyrqA',
                    title: 'Tullow Oil / Codettes Bootcamp 2022 Presentation Axl'
                },
            ],
        })
    }
</script>

<style scoped>

</style>
