import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export const store = new Vuex.Store({
  strict: true,
  state: {
    errors: [],
    privacyDialog: false,
    disclaimerDialog: false
  },
  getters: {
    getErrors: state => {
      return state.errors
    },
    getPrivacyDialog: state => {
      return state.privacyDialog
    },
    getDisclaimerDialog: state => {
      return state.disclaimerDialog
    }
  },
  mutations: {
    CHANGEPRIVACYDIALOG(state, payload) {
      state.privacyDialog = payload
    },
    CHANGEDISCLAIMERDIALOG(state, payload) {
      state.disclaimerDialog = payload
    },
    addError(state, payload) {
      state.errors.push(payload)
    },
    clearErrors(state) {
      state.errors = []
    }
  },
  actions: {
    addError(context, errors) {
      context.commit('addError', errors)
    },
    openPrivacyDialog(context) {
      context.commit('CHANGEPRIVACYDIALOG', true)
    },
    closePrivacyDialog(context) {
      context.commit('CHANGEPRIVACYDIALOG', false)
    },
    openDisclaimerDialog(context) {
      context.commit('CHANGEDISCLAIMERDIALOG', true)
    },
    closeDisclaimerDialog(context) {
      context.commit('CHANGEDISCLAIMERDIALOG', false)
    }
  },
  modules: {

  }
})