<template>
    <v-app>
        <navigation-bar/>
        <v-main class="grey lighten-2">
            <v-sheet id="main-content">
                <v-container>
                    <v-row>
                        <v-col
                                class="mt-2"
                                cols="12"
                        >
                            <router-view/>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-main>
        <codettes-footer/>
    </v-app>
</template>

<script>
    import NavigationBar from "./components/NavigationBar";
    import CodettesFooter from "./components/CodettesFooter";

    export default {
        name: 'App',
        components: {CodettesFooter, NavigationBar},

        data: () => ({
            //
        }),
    };
</script>
