<template>
    <v-lazy transition="fab-transition">
        <v-card>

        <div class="responsive-youtube">
            <iframe :src="video"
                    :title="title"
                    frameborder="0"
                    allow=""
                    allowfullscreen></iframe>
        </div>
            <v-card-title>{{ title }}</v-card-title>
            <v-card-text>{{ description }}</v-card-text>
        </v-card>
    </v-lazy>
</template>

<script>
    export default {
        name: "YoutubeVid",
        props: {
            title: String,
            description: String,
            video: String
        }
    }
</script>

<style scoped>
    .responsive-youtube {
        padding-top: 30px;
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .responsive-youtube::after {
        padding-bottom: 56.25%;
        display: block;
        content: '';
    }

    .responsive-youtube iframe,
    .responsive-youtube object,
    .responsive-youtube embed {
        position: absolute;
        border: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
</style>