<template>
    <div>
        <v-app-bar
                absolute
                app
                class="d-flex"
        >
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-img src='@/assets/img/logo/logo_full.svg' max-width='200px'/>
                <v-tabs align-with-title background-color="primary"
                        v-model="activeTab" class="d-none d-sm-flex">
                    <v-tab
                            v-for="tab in links"
                            :key="tab.title"
                            color="#CE128A"
                            @click="$vuetify.goTo('#' + tab.link, {})"
                    >
                        {{ tab.title }}
                    </v-tab>
                </v-tabs>
        </v-app-bar>

        <v-navigation-drawer
                v-model="drawer"
                fixed
                temporary
                class="d-flex"
        >
            <v-list
                    nav
                    dense
            >
                <v-list-item v-for="(item, index) in links" :key="index" @click="$vuetify.goTo('#' + item.link, {})">
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>

    export default {
        name: "NavigationBar",
        data: () => ({
            drawer: false,
            activeTab: null,
            links: [
                {
                    title: 'Home',
                    link: '/',
                    icon: 'fas fa-home'
                },
                {
                    title: 'Activities',
                    link: 'Activities',
                    icon: 'fas fa-user-hard-hat'
                },
                {
                    title: 'About',
                    link: 'About',
                    icon: 'fas fa-user-astronaut'
                },
                {
                    title: 'Portfolio',
                    link: 'Portfolio',
                    icon: 'fad fa-file-chart-pie'
                },
                {
                    title: 'News',
                    link: 'News',
                    icon: 'far fa-newspaper'
                },
                {
                    title: 'Sponsors',
                    link: 'Sponsors',
                    icon: 'far fa-newspaper'
                },
            ]
        }),
        methods: {
            openPrivacyDialog() {
                this.$store.dispatch('openPrivacyDialog')
            },
            openDisclaimerDialog() {
                this.$store.dispatch('openDisclaimerDialog')
            }
        }
    }
</script>

<style scoped>

</style>