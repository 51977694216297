<template>
    <v-btn :href="this.location" :color="color" rounded target="_blank" fixed top right>
        {{ this.text }}
        <v-icon right large>{{ icon }}</v-icon>
    </v-btn>
</template>

<script>
    export default {
        name: "BuyMeACoffee",
        props: {
            url: {
                type: String,
                required: true
            },
            text: {
                type: String,
                default: 'Buy me a coffee'
            },
            icon: {
                type: String,
                required: true
            },
            color: {
                type: String,
                required: true
            }
        },
        computed: {
            location: function () {
                return 'https://www.buymeacoffee.com/' + this.url
            }
        }
    }
</script>

<style scoped>
.v-btn{
    height: 40px !important;width: 150px !important;
    font-family: "Cookie";
    text-transform: none;
}
</style>