<template>
    <div id="Home">
        <h1>Welcome to Codettes Community</h1>
        <v-carousel
                        :continuous="false"
                        cycle
                        :show-arrows="false"
                        hide-delimiter-background
                        delimiter-icon="mdi-minus"
                        height="500">
                    <v-carousel-item
                            v-for="(slide, i) in slides"
                            :key="i" :src="slide.image"
                            reverse-transition="fade-transition"
                            transition="fade-transition"/>
                </v-carousel>
        <h2><a href="mailto:info@codettes.org?subject='Join codettes'">Join Us Now!</a></h2>
        <v-img src='@/assets/img/logo/archi.png'/>
    </div>
</template>

<script>
    export default {
        name: "CHome",
        components: {},
        data: () => ({
            slides: [
                {
                    image: require('@/assets/img/corporate/slider1.jpg'),
                    title: 'Team'
                },
                {
                    image: require('@/assets/img/corporate/slider2.png'),
                    title: 'Winners Carribean Girls Hackathon 2021'
                },
                {
                    image: require('@/assets/img/corporate/slider3.jpg'),
                    title: 'Winners Carribean Girls Hackathon 2021'
                },
				{
                    image: require('@/assets/img/corporate/slider4.jpg'),
                    title: 'Winners Carribean Girls Hackathon 2021'
                },
				{
                    image: require('@/assets/img/corporate/slider5.jpg'),
                    title: 'Winners Carribean Girls Hackathon 2021'
                }
            ]
        })
    }
</script>

<style scoped>

</style>