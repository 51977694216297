<template>
    <div class="container" id="Sponsors">
        <div class="row">
            <div class="col-xs-12">
                <h2>Sponsors</h2>
            </div>
        </div>
        <div class="row">
            <div v-for="(sponsor, index) in sponsors" :key="index"
                 class="col-xs-12 col-sm-3 col-md-3 text-left item_left" >
                <v-card :href="sponsor.website" target="_blank" class="pa-2">
                    <img :src='sponsor.image' style="object-fit: contain; aspect-ratio: 3/2; width: 80%;" />
                    <v-card-title>{{ sponsor.title }}</v-card-title>
                    <v-card-text></v-card-text>
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CSponsors",
        data: () => ({
            sponsors: [
                {
                    image: '/usembassy.svg',
                    title: 'U.S. Embassy',
                    website: 'https://www.usembassy.gov/'
                },
                {
                    image: '/kosmosenergy.svg',
                    title: 'Kosmos Energy',
                    website: 'https://www.kosmosenergy.com/'
                },
                {
                    image: '/TullowOil2.svg',
                    title: 'Tullow Oil',
                    website: 'https://www.tullowoil.com//'
                },
                {
                    image: '/chevron.svg',
                    title: 'IOT Labratory',
                    website: 'https://www.facebook.com/iotlabsu'
                },
				{
                    image: '/IOTLab.jpeg',
                    title: 'Chevron',
                    website: 'https://www.chevron.com/'
                }

            ]
        })
    }
</script>

<style scoped>

</style>
