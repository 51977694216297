<template>
    <v-footer app absolute class="grey darken-3 white--text" height="300" padless>
        <v-row class="grey darken-3 white--text">
            <v-col cols="12" md="4">
                <v-card flat class="grey darken-3 white--text">
                    <v-img src='@/assets/img/logo/logo_full.svg' max-width='100px'></v-img>
                    <br/>
                    <p>Organization who's aim is to bring awareness, to educate, and train girls and young women in
                        ICT and innovation.
                    </p>
<!--                    <buy-me-a-coffee url='codettes' text='Buy us pizza' icon='fas fa-pizza-slice' color='primary' />-->
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
            </v-col>
            <v-col cols="12" md="4">
                <v-card flat class="grey darken-3 white--text">
                    <h4>Contact info</h4>
                    <ul>
                        <li>
                            <v-icon small color="white">fas fa-map-marker-alt</v-icon>
                            Paramaribo Suriname
                        </li>
                        <li>
                            <v-icon small color="white">fas fa-phone-alt</v-icon>
                            <a href="tel://597-8822698">
                                +597-8822698
                            </a></li>
                        <li>
                            <v-icon small color="white">fab fa-whatsapp</v-icon>
                            <a href="https://wa.me/5978822698">+597-8822698</a></li>
                        <li>
                            <v-icon small color="white">fas fa-envelope</v-icon>
                            <a href="mailto:info@codettes.org">info@codettes.org</a>
                        </li>
                        <li>
                            <v-icon small color="white">fas fa-globe</v-icon>
                            <a href="https://www.codettes.org">https://www.codettes.org</a>
                        </li>
                        <li>
                            <v-icon small color="white">fab fa-facebook</v-icon>
                            <a href="https://www.facebook.com/codettesorg/" target="_blank">codettes</a>
                        </li>
                    </ul>
                </v-card>
            </v-col>
<!--            <v-col cols="12" md="4">-->
<!--                <v-card flat class="grey darken-3 white&#45;&#45;text">-->
<!--                    <h4>Sponsors</h4>-->
<!--                    <ul class="sponsors">-->
<!--                        <li>-->
<!--                            <v-img alt="U.S. Embassy" src='/usembassy.svg' min-width="50" max-width="200"/>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <v-img alt="Kosmos" src='/kosmosenergy.svg' min-width="50" max-width="200" />-->
<!--                        </li>-->
<!--                    </ul>-->
<!--                </v-card>-->
<!--            </v-col>-->
        </v-row>
        <v-row class="bottom black">
            <v-col cols="6">
                Copyright &copy; 2021 Codettes. All rights reserved. Design by Codettes.
            </v-col>
            <v-col cols="6">
                <v-spacer/>
                <ul>
                    <li><a href="https://www.instagram.com/codettesorg/" target="_blank">
                        <v-icon large color="white">fab fa-instagram</v-icon>
                    </a>
                    </li>
                    <li><a href="https://www.facebook.com/codettesorg" target="_blank">
                        <v-icon large color="white">fab fa-facebook</v-icon>
                    </a>
                    </li>
                </ul>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script>
    // import BuyMeACoffee from "../components/BuyMeACoffee";
    export default {
        name: "CodettesFooter"
        // ,
        // components: {BuyMeACoffee}
    }
</script>

<style scoped>
    .sponsors li {
        display: inline-block;
    }

    .sponsors li .v-image {
        background: #ff00;
        border: 0;
    }

    ul {
        list-style: none;
    }

    .bottom li {
        display: inline-block;
    }

</style>