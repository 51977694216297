<template>
    <div class="container-fluid" id="Portfolio">
        <div class="row">
            <div class="col-xs-12">
                <h2>Portfolio</h2>
                <p>We are always passionate on new idea generation and development</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-3" v-for="(item, index) in items"
                 :key="index">
                <v-card>
                    <a :href=item.link>
                        <v-img :lazy-src='item.thumb_image' :src='item.thumb_image'/>
                    </a>
                    <v-card-actions>
                        <v-btn v-if="item.image" :href="item.image"></v-btn>
                    </v-card-actions>
                    <v-card-text>
                        {{ item.description}}
                    </v-card-text>
                </v-card>
            </div>
        </div> <!-- end .row portfolio-wrapper -->
        <div class="row">
            <div v-for="(item, index) in summaryitems" :key="index" class="col-xs-12 col-md-6">
                <youtube-vid :title="item.title" :description="item.description" :video="item.video"/>
            </div>
        </div>
        <div class="row" id="Bootcamps">
            <div class="col-xs-12">
                <h2>Codettes Bootcamp Projects 2020-2021</h2>

            </div>
        </div>
        <div class="row">
            <div v-for="(item, index) in bootcampitems" :key="index" class="col-xs-12 col-md-6">
                <v-lazy>
                    <v-card>
                        <div class="responsive-google-slides">
                            <iframe :src="item.slideshow"
                                    allowfullscreen="true"
                                    mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                        </div>
                        <v-card-title>{{ item.name }}</v-card-title>
                        <v-card-actions>
                            <v-btn :href="item.portfolio" fab>
                                <v-icon>fas fa-tools</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-lazy>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <h4><a href=https://codettes-boothcamp.gitlab.io/2020/students/final/ target="_blank">VIEW MORE CODETTES
                    BOOTCAMP PROJECTS HERE!</a></h4>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <h2>Codettes Inno Starter 2020 Projects</h2>
                <p>We are always passionate on new idea generation and development</p>
            </div>
        </div>
        <div class="row">
            <div v-for="(item, index) in innostarter2020" :key="index" class="col-xs-12 col-md-6">
                <v-lazy>
                    <v-card>
                        <div class="responsive-google-slides">
                            <iframe :src="item.slideshow" frameborder="0"
                                    rm="minimal"
                                    allowfullscreen="true"
                                    mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                        </div>
                        <v-card-title>{{ item.name }} - {{item.title }}</v-card-title>
                        <v-card-actions>
                            <v-btn :href="item.portfolio" fab>
                                <v-icon>fas fa-tools</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-lazy>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <h4>
                    <a href='https://innostarter.gitlab.io/2020/students/final/' target='_blank'>
                        VIEW MORE INNOSTARTER PROJECTS HERE!
                    </a>
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
    import YoutubeVid from "../components/YoutubeVid";

    export default {
        name: "CPortfolio",
        components: {YoutubeVid},
        data: () => ({
            items: [
                {
                    thumb_image: 'https://lh3.googleusercontent.com/FSxwFLAhmZ1VS8L9NR6cnnpvvWlSyLSNbZAYCvONv1bg8j0sWjjYm-l6mXKrLFO42nJiAY-2323xhLVoJj5YOtEAksOglJjYh6IuP9REP9P42keL30SOiKP6ATPSfOMMdVSIcwTnvQ=w480-h315-p-k',
                    image: '',
                    description: 'Team Sucode Squad Home Security Monitoring System',
                    link: 'https://lh3.googleusercontent.com/FSxwFLAhmZ1VS8L9NR6cnnpvvWlSyLSNbZAYCvONv1bg8j0sWjjYm-l6mXKrLFO42nJiAY-2323xhLVoJj5YOtEAksOglJjYh6IuP9REP9P42keL30SOiKP6ATPSfOMMdVSIcwTnvQ=w2400'
                },
                {
                    thumb_image: 'https://lh3.googleusercontent.com/N4cmShOA7NiO_5Q_tJ06yQ_0MA49JXiwSCLzKN8os3-eecwPBZ-rGoysqxXIYiWFnDHKSZb_n2FIITWKMol1YVe_QqTSOmc9VXqfaZitYlj8xdSp_vVxds3l4ouPigZfTGchKJ2-Pw=w480-h315-p-k',
                    image: '',
                    description: 'Codettes Vloggers at Kosmos/IT-Core HackOmation2018',
                    link: 'https://lh3.googleusercontent.com/N4cmShOA7NiO_5Q_tJ06yQ_0MA49JXiwSCLzKN8os3-eecwPBZ-rGoysqxXIYiWFnDHKSZb_n2FIITWKMol1YVe_QqTSOmc9VXqfaZitYlj8xdSp_vVxds3l4ouPigZfTGchKJ2-Pw=w2400'
                },
                {
                    link: 'https://lh3.googleusercontent.com/Srwcavbqc4h20NlniBDAedtWrCFhfKy7BXF22Fv2ZFpbV71tBmskhJbQOamhSbh65ZJX8J1_TiSzAeBvAEOT2qFxsB9-H2-wUsoa_-VbJD8IJ0JR7Zell9yuwsmaZxIEQC7wxLMSgg=w2400',
                    thumb_image: 'https://lh3.googleusercontent.com/Srwcavbqc4h20NlniBDAedtWrCFhfKy7BXF22Fv2ZFpbV71tBmskhJbQOamhSbh65ZJX8J1_TiSzAeBvAEOT2qFxsB9-H2-wUsoa_-VbJD8IJ0JR7Zell9yuwsmaZxIEQC7wxLMSgg=w480-h315-p-k',
                    description: 'Codettes at Kosmos/IT-Core HackOmation2018',
                    image: ''
                },
                {
                    link: 'https://lh3.googleusercontent.com/hF84CZmfyfeEMXP4_VJgrg2yDSBJH4QEyx4g_eB5y1Xefu-Kqrdi2eOnNCpbFQ07Z73soYV2UTEx7XRuGet9dEWwe7_X7eQB6yrKhHgRdMspihIzpf0suOIaIziXEUL-cOiAl6Ju9A=w2400',
                    thumb_image: 'https://lh3.googleusercontent.com/hF84CZmfyfeEMXP4_VJgrg2yDSBJH4QEyx4g_eB5y1Xefu-Kqrdi2eOnNCpbFQ07Z73soYV2UTEx7XRuGet9dEWwe7_X7eQB6yrKhHgRdMspihIzpf0suOIaIziXEUL-cOiAl6Ju9A=w480-h315-p-k',
                    description: 'Team Cavalion Winner of Hackathon 2016 Paramaribo Suriname',
                    image: ''
                },
                {
                    link: 'https://lh3.googleusercontent.com/jPmCpeKC0hSUw2Mp49aK3kC10zm8ZstYpS1LBsCoCdp1bcF-1hETws0MZdC8OxlV5TTnBPSw6LQXt8giMsSuvQ7jiRVvrrtvXedoAm0XiZZgua1EhYlD3McBAX7hPl05Ye6D_zP83g=w2400',
                    thumb_image: 'https://lh3.googleusercontent.com/jPmCpeKC0hSUw2Mp49aK3kC10zm8ZstYpS1LBsCoCdp1bcF-1hETws0MZdC8OxlV5TTnBPSw6LQXt8giMsSuvQ7jiRVvrrtvXedoAm0XiZZgua1EhYlD3McBAX7hPl05Ye6D_zP83g=w480-h315-p-k',
                    description: 'Team Cavalion at HackOmation 2017 Detect & Act Security System',
                    image: ''
                },
                {
                    link: 'https://lh3.googleusercontent.com/hdFgPm80YvlG0-p6d7jEWBXIxWGXVpVN7Cy7937kkF21j3r8zz9Z86ifV3w-Vk9FovCsnQV27xJtvHCduF4RMjJDBdxVF5rnMgM5vw16E9ZK1u7JYywzP4liEZdChvzWwAcAsgau3A=w2400',
                    thumb_image: 'https://lh3.googleusercontent.com/hdFgPm80YvlG0-p6d7jEWBXIxWGXVpVN7Cy7937kkF21j3r8zz9Z86ifV3w-Vk9FovCsnQV27xJtvHCduF4RMjJDBdxVF5rnMgM5vw16E9ZK1u7JYywzP4liEZdChvzWwAcAsgau3A=w480-h315-p-k',
                    description: 'Junior Robokidz Ev3 Mindstorm Robotics',
                    image: ''
                },
                {
                    link: require('@/assets/img/corporate/julie.jpg'),
                    thumb_image: 'https://lh3.googleusercontent.com/htBcGFPBzzmDhFR-o-eajwatPhD9qKXz1ltI84gKVP8APidMMzp9kiNpiKLQhJZOybHxWK7TuvJ2J6r91dLMBEM2Z6kfSjus1DdFj_IzW12rFO7bQMdIACQNdiOGhashDyj5Xp7M8w=w480-h315-p-k',
                    description: 'Julie Sundar Arduino Mobile Robot Innostarter 2021',
                    image: ''
                },
                {
                    link: 'https://lh3.googleusercontent.com/MNE4GzDelTbbzDl-heAZ0qN5oFAut-hF-38X5qcZmnrKnXvNgQQA-YkVVTGAM7HJTXBwzGfTN8z1URa8z-Os3ElaLVsMcDGnbZ_LxVFVw-KeJUBp8KbXVphnGLyuP7VemBdwRJk8hw=w2400',
                    thumb_image: 'https://lh3.googleusercontent.com/MNE4GzDelTbbzDl-heAZ0qN5oFAut-hF-38X5qcZmnrKnXvNgQQA-YkVVTGAM7HJTXBwzGfTN8z1URa8z-Os3ElaLVsMcDGnbZ_LxVFVw-KeJUBp8KbXVphnGLyuP7VemBdwRJk8hw=w480-h315-p-k',
                    //link: 'https://lh3.googleusercontent.com/668dIuZGMqJX60-3LgXY8DVFXyDBPjGQlHNeEGALtlLG8LGMOcO3UZDquEEiWT3BRGddd3ObGXWtP4_dAfZxAFKIXJ2_t8iHDIWIfx6nrosaVxNPQjroSloqeVW4LZhYNbTm5H050w=w2400',
                    //thumb_image: 'https://lh3.googleusercontent.com/668dIuZGMqJX60-3LgXY8DVFXyDBPjGQlHNeEGALtlLG8LGMOcO3UZDquEEiWT3BRGddd3ObGXWtP4_dAfZxAFKIXJ2_t8iHDIWIfx6nrosaVxNPQjroSloqeVW4LZhYNbTm5H050w=w480-h315-p-k',
                    description: 'Julie Sundar at Kosmos/IT-Core HackOmation Hands on Session',
                    image: ''
                }
            ],
            summaryitems: [
                {
                    title: 'Codettes Inno Starter 2020 Summary',
                    video: 'https://www.youtube.com/embed/f_r7a8esP50',
                    description: 'Intro to Electronics Programming Digital Fabrication and Robotics'
                },
                {
                    title: 'Codettes Bootcamp 2020 Summary',
                    video: 'https://www.youtube.com/embed/etHGr0q9QXs',
                    description: 'Advanced Electronics Programming Digital Fabrication and Business'
                }
            ],
            bootcampitems: [
                {
                    slideshow: 'https://docs.google.com/presentation/d/e/2PACX-1vQXMHvj2ag3X_EwOnfmFsQgEAMHdC_miCJf4_90LONEpXzpYlwbLfRCITIpFImZpRNs-SCjmO755xPj/embed?start=false&loop=true&delayms=3000',
                    name: 'Devika Mahabir',
                    portfolio: 'https://codettes-boothcamp.gitlab.io/students/devika-mahabir/'
                },
                {
                    slideshow: 'https://docs.google.com/presentation/d/e/2PACX-1vRHUe14pIo4ZCjmkqJgJyUx1WQevac4iAyfDGueNsL97pAwMddzZxAX5VqXxXLim-mMaTy87-TVtYb1/embed?start=false&loop=true&delayms=3000',
                    name: 'Shewiskha Ganpat',
                    portfolio: 'https://codettes-boothcamp.gitlab.io/students/suraisha-ganpat/'
                },
                {
                    slideshow: 'https://docs.google.com/presentation/d/e/2PACX-1vRV63vIDDio_T55iJYfb-3VINA3KosUfYnsmgLfZD3lojT0OEG706oPuz7q3iytr1oaExl4LJmXs_7K/embed?start=false&loop=true&delayms=3000',
                    name: 'Ivy Amatredjo',
                    portfolio: 'https://codettes-boothcamp.gitlab.io/students/ivy-amatredjo/'
                },
                {
                    slideshow: 'https://docs.google.com/presentation/d/e/2PACX-1vTb0Ra-xtvhWBunoCLYw4o8pHs_YXGa5L7Cc-tnOV_i5D6T60pNHHuvj3yAjbBAiDUU3YCJNAnT8Ji8/embed?start=false&loop=true&delayms=3000',
                    name: 'Anjessa Linger',
                    portfolio: 'https://codettes-boothcamp.gitlab.io/students/anjessa-linger/'
                }
            ],
            innostarter2020: [
                {
                    slideshow: 'https://docs.google.com/presentation/d/e/2PACX-1vR2xOysKcPlRyh1EGbmkxgmI57i0cLoI08WyIE1qRb93wruEw8H7k27WALr-G4LmhZGctwM98Etl06P/embed?start=false&loop=true&delayms=3000',
                    portfolio: 'https://innostarter.gitlab.io/2020/students/weeks/jyaistagangadin/',
                    name: 'Jyaista Gangadin',
                    title: 'Final Project Video Backoff'
                },
                {
                    slideshow: 'https://docs.google.com/presentation/d/e/2PACX-1vT9pxlFg74lMFdCvbXLBxAZmM1mE0RZqkTLyn2gqGDE23J-B5SekkUtMtaCKfMTT_ndkLeH3AHFozb9/embed?start=false&loop=false&delayms=3000',
                    portfolio: 'https://innostarter.gitlab.io/2020/students/weeks/ghillesdipotroeno/',
                    name: 'Shyfka Landveld & Ghilles Dipotroeno',
                    title: 'Final Project'
                },
                {
                    slideshow: 'https://docs.google.com/presentation/d/e/2PACX-1vSBQPPA8KQyrvaxs9TiPcKdXYkkT7VnxDQ1utkmryI0Ii0l0U_dmhcWr3MHP4xn4TVEgkBB7JzMFF3-/embed?start=false&loop=true&delayms=3000',
                    portfolio: 'https://innostarter.gitlab.io/2020/students/weeks/shaniesramdien/',
                    name: 'Shanice Ramdien & Darlene Gefferie',
                    title: 'Final Project'
                },
                {
                    slideshow: 'https://docs.google.com/presentation/d/e/2PACX-1vTy-j3zkhqyjH0DGm4oqKfIx9pKcjONze-dFPmfJGpuMaqBV14oSFcqaOL1QimEMZOnMEfWP_AVRoYm/embed?start=false&loop=true&delayms=3000',
                    portfolio: 'https://innostarter.gitlab.io/2020/students/weeks/adjakomanischa/',
                    name: 'Adjako Manischa',
                    title: 'Final Project Video Handzoff'
                }
            ]
        }),
        computed: {
            video() {
                switch (this.$vuetify.breakpoint.name) {
                    case 'xs':
                        return {
                            width: "560",
                            height: "315"
                        }
                    case 'sm':
                        return {
                            width: "560",
                            height: "315"
                        }
                    case 'md':
                        return {
                            width: "560",
                            height: "315"
                        }
                    case 'lg':
                        return {
                            width: "560",
                            height: "315"
                        }
                    case 'xl':
                        return {
                            width: "560",
                            height: "315"
                        }
                    default:
                        return {
                            width: "560",
                            height: "315"
                        }
                }
            }
        }

    }
</script>

<style scoped>

    .responsive-google-slides {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 Ratio */
        height: 0;
        overflow: hidden;
    }

    .responsive-google-slides iframe {
        border: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
</style>
