<template>
    <div>
        <v-btn
                v-scroll="onScroll"
                v-show="fab"
                fab
                dark
                fixed
                bottom
                right
                color="primary"
                @click="toTop"
        >
            <v-icon>fas fa-chevron-up</v-icon>
        </v-btn>
        <home/>
        <activities/>
        <about/>
		<news/>
        <portfolio/>
        <buy-me-a-coffee url='codettes' text='Buy us pizza' icon='fas fa-pizza-slice' color='primary' />
        <sponsors />

    </div>
</template>

<script>
    import Home from '@/pages/Home'
    import Activities from "@/pages/Activities";
    import About from "@/pages/About";
    import Portfolio from "@/pages/Portfolio";
    import News from "@/pages/News";
    import BuyMeACoffee from "../components/BuyMeACoffee";
    import Sponsors from "@/pages/Sponsors";

    export default {
        name: "SinglePage",
        components: {Home, Activities, About, Portfolio, News, BuyMeACoffee, Sponsors},
        data: () => ({
            fab: false
        }),
        methods: {
            onScroll(e) {
                if (typeof window === 'undefined') return
                const top = window.pageYOffset || e.target.scrollTop || 0
                this.fab = top > 20
            },
            toTop() {
                this.$vuetify.goTo(0, {})
            }
        }
    }
</script>

<style scoped>

</style>